<template>
  
</template>

<script>

export default {
  name: 'PowerUpsHistorico',
}

</script>
